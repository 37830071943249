import React, {useEffect, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import {Image, ListGroup, Spinner} from "react-bootstrap";
import logo from "../logo.png";
import {useLocalStorage} from "usehooks-ts";
import ApiUrl from "../ApiUrl";

const ChooseUserPage = () => {
    const [token] = useLocalStorage<string>("jwtToken", "");
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);
    useEffect(() => {
        setUsersLoading(true);
        const fetchUsers = async () => {
            const resp = await fetch(`${ApiUrl}/api/v1/users`,
                {
                    headers: { 'Authorization': 'Bearer ' + token },
                })
            if (!resp.body) {
                throw "no body";
            }
            setUsers(await resp.json());
        }
        fetchUsers().catch(console.error).finally(() => setUsersLoading(false));
    }, [token]);

    return (
        <div className="App">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Link to={`/`} className={"navbar-brand"} title={"Version " + process.env.REACT_APP_VERSION}>
                        <Image src={logo} style={{"height": "1.8rem"}} className={"align-text-top"}/>
                        <Navbar.Brand className={"ms-1 d-none d-lg-inline"}>Leiter M</Navbar.Brand>
                    </Link>
                </Container>
            </Navbar>
            <Container className={"mt-4"}>
                <h2>Benutzer wählen</h2>
                <Container className={"environment-list-container"}>
                    <ListGroup className={"mt-4"}>
                        {usersLoading &&
                            <ListGroup.Item>
                                <Spinner animation={"border"} size="sm"></Spinner>
                            </ListGroup.Item>
                        }
                        {!usersLoading && users.map((e: any) => (
                            <ListGroup.Item key={e.id}>
                                <Link to={`/pin/${e.id}`} className={"link-underline link-underline-opacity-0 link-underline-opacity-100-hover stretched-link"}>{e.name}</Link>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Container>
            </Container>
        </div>
    );
};

export default ChooseUserPage;
