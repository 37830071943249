import React from 'react';
import './App.css';
import ChooseEnvironmentPage from "./pages/ChooseEnvironmentPage";
import {useLocalStorage} from "usehooks-ts";
import MainPage from "./pages/MainPage";
import ChooseUserPage from "./pages/ChooseUserPage";
import {ErrorBoundary, FallbackProps} from "react-error-boundary";
import {Alert, Button} from "react-bootstrap";

function App() {
    const [authLevel] = useLocalStorage<string>("authLevel", "");

    const renderError = ({error, resetErrorBoundary}: FallbackProps) => {
        console.error("error", error);
        return (
            <Alert variant={"danger"}>
                <Alert.Heading as={"h5"}>Fehler</Alert.Heading>
                <pre>{error.message}</pre>
                <Button variant={"secondary"} onClick={resetErrorBoundary}>Wiederholen</Button>
            </Alert>
        );
    }

    const component = () => {
        switch (authLevel) {
            case "user":
                return <MainPage/>;
            case "environment":
                return <ChooseUserPage/>;
            default:
                return <ChooseEnvironmentPage/>;
        }
    };
    return (
        <ErrorBoundary fallbackRender={renderError} onReset={console.log}>
            {component()}
        </ErrorBoundary>
    );
}

export default App;
