import React, {useCallback, useEffect, useState} from "react";
import ItemCard, {AvailableItem} from "./ItemCard";
import Container from "react-bootstrap/Container";
import {useCurrentOrder} from "../pages/MainPage";
import {ReadyState} from "react-use-websocket";
import {Button, Col, Dropdown, Form, FormLabel, Modal, Row} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {useLocalStorage} from "usehooks-ts";
import Nav from "react-bootstrap/Nav";

export interface OrderItem {
    name: string;
    count: number;
    comment: string;
}

export interface OrderScreenProps {
    //available: AvailableItem[];
    //currentOrder: OrderItem[];
    //onCurrentOrderChanged: (order: OrderItem[]) => void;
}

interface Destination {
    id: number;
    name: string;
    default: boolean;
}

const OrderScreen = (props: OrderScreenProps) => {
    const {
        currentOrder,
        setCurrentOrder,
        sendMessage,
        lastMessage,
        readyState,
        setFooterElements,
        setHeaderElements
    } = useCurrentOrder();
    const [items, setItems] = useState<AvailableItem[]>([]);
    const [itemUpdateTimestamp, setItemUpdateTimestamp] = useState<Date>(new Date());
    const [destinations, setDestinations] = useState<Destination[]>([]);
    const [activeDestination, setActiveDestination] = useLocalStorage("activeDestination", -1);
    const [addCommentItem, setAddCommentItem] = useState<AvailableItem | null>(null);
    const [commentCount, setCommentCount] = useState<number>(1);
    const [commentText, setCommentText] = useState<string>("");

    useEffect(() => {
        if (lastMessage == null) {
            console.log("set message to null");
            return;
        }
        let msg = JSON.parse(lastMessage.data);
        console.log("new message: " + msg.command + " / " + JSON.stringify(msg.data));
        switch (msg.command) {
            case "itemList":
                setItems(msg.data);
                setItemUpdateTimestamp(new Date());
                break;
            case "destinationList":
                setDestinations(msg.data);
                break;
        }
    }, [lastMessage]);

    const getActiveIdx = useCallback((): number => {
        if (activeDestination !== -1) {
            let idx = destinations.findIndex(d => d.id === activeDestination);
            if (idx !== -1) {
                return idx;
            }
            // weird: activeDestination is pointing to a destination that we no longer have.
            // better reset the state...
            setActiveDestination(-1);
        }
        let activeIdx = destinations.findIndex(d => d.default);
        if (activeIdx !== -1) {
            return activeIdx;
        }
        return 0;
    }, [activeDestination, destinations, setActiveDestination]);

    useEffect(() => {
        console.log("activeDestination", activeDestination);
    }, [activeDestination]);

    const handleClickSendMessage = useCallback(() => {
        console.log("in handleclick, current", JSON.stringify(currentOrder));
        let data = {command: "order", data: {items: currentOrder, destination_id: destinations[getActiveIdx()].id}};
        console.log("sending", data);
        sendMessage(JSON.stringify(data));
        setCurrentOrder([]);
    }, [currentOrder, destinations, getActiveIdx, sendMessage, setCurrentOrder]);

    useEffect(() => {
        setHeaderElements([
            <Nav className="justify-content-end" key={0}>
                <Button
                    onClick={handleClickSendMessage}
                    disabled={readyState !== ReadyState.OPEN || currentOrder.length === 0}
                    variant={"outline-success"}
                >
                    Bestellen
                </Button>
            </Nav>
        ]);
    }, [readyState, currentOrder, setHeaderElements, handleClickSendMessage]);

    useEffect(() => {
        if (destinations.length === 0) {
            return;
        }
        let activeIdx = getActiveIdx();
        setFooterElements([
            <Dropdown as={Navbar.Text} drop={"up-centered"} key={0}>
                <Dropdown.Toggle variant={"outline-secondary"}>
                    <FontAwesomeIcon icon={faLocationDot} className="me-2"/>
                    {destinations[activeIdx].name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {destinations.map((d, idx) => {
                        if (idx === activeIdx) {
                            return undefined;
                        }
                        return (
                            <Dropdown.Item key={d.id} onClick={() => setActiveDestination(d.id)}>{d.name}</Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        ]);
    }, [destinations, activeDestination, getActiveIdx, setFooterElements, setActiveDestination]);

    /* when connected to the websocket, immediately request a list of items and destinations */
    useEffect(() => {
        console.log("readystate", readyState);
        if (readyState === ReadyState.OPEN) {
            sendMessage(`{"command": "getItemList"}`);
            sendMessage(`{"command": "getDestinationList"}`);
            sendMessage(`{"command": "getNumOpenOrders"}`);
        }
    }, [readyState, sendMessage]);

    const setItemCount = (name: string, count: number) => {
        console.log("name: " + name + ", count: " + count + ", currentOrder: " + JSON.stringify(currentOrder));
        setCurrentOrder((o) => {
            let newOrder = [...o];
            let index = newOrder.findIndex((value) => value.name === name);
            if (index === -1) {
                /* not in array yet */
                if (count === 0) {
                    /* initial update, ignore */
                    return newOrder;
                }
                newOrder.push({name, count, comment: ""});
                console.log("adding new item to order", newOrder);
            } else {
                if (count === 0) {
                    /* if we are decrementing to 0, just delete the whole entry */
                    newOrder.splice(index, 1);
                } else {
                    newOrder[index] = {name, count, comment: o[index].comment};
                }
            }
            return newOrder;
        })
    }

    const saveComment = () => {
        if (addCommentItem === null) {
            return;
        }
        setCurrentOrder((o) => {
            let newOrder = [...o];
            let index = newOrder.findIndex((value) => value.name === addCommentItem.name);
            if (index === -1) {
                /* not in array yet */
                newOrder.push({name: addCommentItem.name, count: commentCount, comment: commentText});
            } else {
                newOrder[index] = {name: addCommentItem.name, count: commentCount, comment: commentText};
            }
            console.log("new order", newOrder);
            return newOrder;
        });
        setCommentCount(1);
        setCommentText("");
        setAddCommentItem(null);
    };

    const showCommentModal = (item: AvailableItem) => {
        setAddCommentItem(item);
        setCommentCount(itemCount(item.name) || 1);
        setCommentText(itemComment(item.name));
    };

    const itemCount = (name: string): number => currentOrder.find((o) => o.name === name)?.count || 0;
    const itemComment = (name: string): string => currentOrder.find((o) => o.name === name)?.comment || "";

    return (
        <Container key={itemUpdateTimestamp.toISOString()} fluid className="mt-3">
            {items.map((item, idx) => (
                <ItemCard
                    key={item.name + "/" + item.available + "/" + itemCount(item.name)}
                    item={item}
                    count={itemCount(item.name)}
                    onCountChanged={(c: number) => setItemCount(item.name, c)}
                    onAddComment={(item: AvailableItem) => showCommentModal(item)}
                    hasComment={itemComment(item.name) !== ""}
                />
            ))}
            {addCommentItem &&
                <Modal
                    show={!!addCommentItem}
                    centered={true}
                    animation={true}
                    onHide={() => setAddCommentItem(null)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{addCommentItem?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group as={Row} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4} htmlFor={"control-available"}>Anzahl</FormLabel>
                                <Col>
                                    <Form.Control
                                        id={"control-count"}
                                        type={"number"}
                                        value={commentCount}
                                        onChange={e => setCommentCount(Number.parseInt(e.target.value))}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4} htmlFor={"control-available"}>Anmerkung</FormLabel>
                                <Col>
                                    <Form.Control
                                        id={"control-comment"}
                                        type={"text"}
                                        value={commentText}
                                        onChange={e => setCommentText(e.target.value)}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setAddCommentItem(null)}>Schließen</Button>
                        <Button variant="primary" onClick={() => saveComment()} disabled={addCommentItem.available !== -1 && (commentCount < 1 || commentCount > addCommentItem.available)}>Speichern</Button>
                    </Modal.Footer>

                </Modal>
            }
        </Container>
    );
}

export default OrderScreen;
