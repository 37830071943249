import {useEffect, useRef, useState} from 'react';
import useReactWebSocket, {ReadyState} from 'react-use-websocket';
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "usehooks-ts";

// Multiple instances of the hook can exist simultaneously.
// This stores the timestamp of the last heartbeat for a given socket url,
// preventing other instances to send unnecessary heartbeats.
const previousHeartbeats: Record<string, number> = {};

interface IWebSocketOptions {
    shareConnection: boolean
}

export interface APIMessage {
    command: string;
    data: any;
}

const useWebSocket = (
    initialUrl?: string,
    options?: IWebSocketOptions,
    onError?: (event: Event) => void,
    shouldReconnect?: (event: CloseEvent) => boolean,
    onHeartbeat?: (deltaMs: number) => void
) => {
    // Stores the heartbeat interval.
    const heartbeatIntervalRef = useRef<number>();
    const navigate = useNavigate();
    const [, setToken] = useLocalStorage<string>("jwtToken", "");
    const [, setAuthLevel] = useLocalStorage<string>("authLevel", "");
    const [url, setUrl] = useState(initialUrl);

    // Instantiate useReactWebSocket.
    const {sendMessage, lastMessage, readyState} = useReactWebSocket<APIMessage>(
        url || null,
        {
            share: options?.shareConnection,
            reconnectAttempts: 6,
            reconnectInterval: 1000,

            onError: onError,
            shouldReconnect: shouldReconnect,
        },
        !!url
    );

    useEffect(() => {
        if (lastMessage == null) {
            return;
        }
        let cmd = JSON.parse(lastMessage.data);
        console.log("message", cmd);
        if (cmd.command === "error") {
            console.error(cmd.data);
            // TODO this is kind of janky...
            setUrl(undefined);
            setToken('');
            setAuthLevel('none');
            navigate('/');
            return;
        }
        if (cmd.command === "pong") {
            let now = window.performance.now();
            if (url) {
                let delta = now - previousHeartbeats[url];
                delta = Math.round(delta * 100) / 100;
                if (onHeartbeat) {
                    onHeartbeat(delta);
                }
            }
            return;
        }
    }, [lastMessage, navigate, onHeartbeat, setAuthLevel, setToken, url]);

    // Sends a periodical heartbeat message through the websocket connection.
    useEffect(() => {
        console.log("readystate", readyState)
        if (readyState === ReadyState.OPEN) {
            const doHb = () => {
                if (url) {
                    let now = window.performance.now();
                    const lastHeartbeat = previousHeartbeats[url];
                    const deltaFromNow = (now - lastHeartbeat) / 1000;

                    // Send a heartbeat message if it hasn't already been sent within the last 10 seconds.
                    if (!lastHeartbeat || deltaFromNow > 10) {
                        // Send the heartbeat message and update the heartbeat history.
                        sendMessage('{"command": "ping"}');
                        previousHeartbeats[url] = now;
                    }
                }
            }
            heartbeatIntervalRef.current = window.setInterval(doHb, 2000);
            doHb();
        }

        return () => {
            clearInterval(heartbeatIntervalRef.current);
        };
    }, [url, readyState, sendMessage]);

    return {sendMessage, lastMessage, readyState};
};

export default useWebSocket;
