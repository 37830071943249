import {ReadyState} from "react-use-websocket";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignal} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const OnlineStatus = (props: { readyState: ReadyState, pingDelay: number }) => {
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Verbinde',
        [ReadyState.OPEN]: 'Online',
        [ReadyState.CLOSING]: 'Verbindung wird getrennt',
        [ReadyState.CLOSED]: 'Offline',
        [ReadyState.UNINSTANTIATED]: 'Instantiating',
    }[props.readyState];
    const connectionColor = {
        [ReadyState.CONNECTING]: 'text-warning',
        [ReadyState.OPEN]: 'text-success',
        [ReadyState.CLOSING]: 'text-warning',
        [ReadyState.CLOSED]: 'text-danger',
        [ReadyState.UNINSTANTIATED]: 'text-danger',
    }[props.readyState];
    return (
        <>
            <FontAwesomeIcon icon={faSignal} className={connectionColor}/>
            <span className="ms-1">{connectionStatus}{props.readyState === ReadyState.OPEN && props.pingDelay > 0 && ` (${props.pingDelay.toLocaleString()} ms)`}</span>
        </>
    )
};

export default OnlineStatus;
