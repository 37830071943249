import {Button, Col, Form, FormLabel, Modal, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrashCan, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {ReadyState} from "react-use-websocket";

interface User {
    id?: number;
    name: string;
    role: string;
    pin: string;
}

interface UsersViewProps {
    readyState: ReadyState;
    sendMessage: (message: string) => void;
    lastMessage: MessageEvent<any> | null;
}

const UsersView = ({readyState, sendMessage, lastMessage}: UsersViewProps) => {
    const [editedUser, setEditedUser] = useState<User | null>(null);
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const [userToCreate, setUserToCreate] = useState<User | null>(null);
    const [users, setUsers] = useState<User[]>([]);

    const setEditedName = (name: string) => {
        setEditedUser((user) => user ? {...user, name} : null);
    };
    const setEditedRole = (role: string) => {
        setEditedUser((user) => user ? {...user, role} : null);
    };
    const setEditedPin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            setEditedUser((user) => user ? {...user, pin: e.target.value} : null);
        } else {
            e.preventDefault();
        }
    };

    const setToCreateName = (name: string) => {
        setUserToCreate((user) => user ? {...user, name} : null);
    };

    const setToCreateRole = (role: string) => {
        setUserToCreate((user) => user ? {...user, role} : null);
    };

    const setToCreatePin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            setUserToCreate((user) => user ? {...user, pin: e.target.value} : null);
        } else {
            e.preventDefault();
        }
    };

    const saveUser = (user: User | null) => {
        console.log(user);
        sendMessage(`{"command": "admin/modifyUser", "data": ${JSON.stringify(user)}}`);
        setEditedUser(null);
    };

    const deleteUser = (user: User | null) => {
        console.log(user);
        setUserToDelete(null);
    }

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendMessage(`{"command": "admin/getUserList"}`);
        }
    }, [readyState, sendMessage]);

    useEffect(() => {
        if (lastMessage == null) {
            console.log("set message to null");
            return;
        }
        let msg = JSON.parse(lastMessage.data);
        console.log("new message: " + msg.command + " / " + JSON.stringify(msg.data));
        switch (msg.command) {
            case "admin/userList":
                setUsers(msg.data);
                break;
        }
    }, [lastMessage]);

    const roleToText: Record<string, string> = {
        "admin": "Administrator",
        "receiver": "Austräger",
        "sender": "Besteller",
    }

    const textToRole = (t: string): string => {
        switch (t) {
            case "Administrator":
                return "admin";
            case "Austräger":
                return "receiver";
            case "Besteller":
                return "sender";
            default:
                return "";
        }
    }

    const checkAndSetPin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    }

    return (
        <div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Rolle</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map((user, idx) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{roleToText[user.role]}</td>
                            <td>
                                <Button variant={"link"} size={"sm"} className={"p-0"} onClick={() => setEditedUser(user)}>
                                    <FontAwesomeIcon icon={faPenToSquare}/>
                                </Button>
                                <Button variant={"link"} size={"sm"} className={"p-0 ms-3 text-danger"} onClick={() => setUserToDelete(user)}>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button variant={"success"} className={"mt-4"} onClick={() => setUserToCreate({name: "", role: "administator", pin: ""})}>
                <FontAwesomeIcon icon={faUserPlus} size={"sm"} className={"me-2"}/> Benutzer hinzufügen
            </Button>
            {users && editedUser !== null &&
                <Modal
                    show={!!editedUser}
                    onHide={() => setEditedUser(null)}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Bearbeiten</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group as={Row} controlId={"user-name"} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4}>Name</FormLabel>
                                <Col>
                                    <Form.Control type={"text"} value={editedUser?.name ?? ""} onChange={(e) => setEditedName(e.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4} htmlFor={"control-role"}>Rolle</FormLabel>
                                <Col>
                                    <Form.Select id={"control-role"} value={editedUser?.role ?? ""} onChange={(e) => setEditedRole(e.target.value)}>
                                        <option value={"admin"}>Administrator</option>
                                        <option value={"receiver"}>Austräger</option>
                                        <option value={"sender"}>Besteller</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId={"user-pin"} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4}>Pin</FormLabel>
                                <Col>
                                    <Form.Control
                                        type={"text"}
                                        inputMode={"numeric"}
                                        value={editedUser?.pin ?? ""}
                                        onChange={(e) => setEditedPin(e)}
                                        placeholder={"nicht geändert"}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setEditedUser(null)}>Schließen</Button>
                        <Button variant="primary" onClick={() => saveUser(editedUser)}>Speichern</Button>
                    </Modal.Footer>
                </Modal>
            }
            {users && userToDelete !== null &&
                <Modal
                    show={!!userToDelete}
                    onHide={() => setUserToDelete(null)}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Benutzer <b>{userToDelete.name}</b> wirklich löschen?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setUserToDelete(null)}>Abbrechen</Button>
                        <Button variant="danger" onClick={() => deleteUser(userToDelete)}>Löschen</Button>
                    </Modal.Footer>
                </Modal>
            }
            {users && userToCreate !== null &&
                <Modal
                    show={!!userToCreate}
                    onHide={() => setUserToCreate(null)}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Neuen Benutzer erstellen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group as={Row} controlId={"user-name"} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4}>Name</FormLabel>
                                <Col>
                                    <Form.Control type={"text"} value={userToCreate.name} onChange={(e) => setToCreateName(e.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4} htmlFor={"control-role"}>Rolle</FormLabel>
                                { /*
                                TODO next time: finalize modifying users (role is not set correctly)
                                implement backend function to add a new user
                                implement backend function to delete a user
                                */ }
                                <Col>
                                    <Form.Select id={"control-role"} value={userToCreate.role ?? ""} onChange={(e) => setToCreateRole(e.target.value)}>
                                        <option value={"admin"}>Administrator</option>
                                        <option value={"receiver"}>Austräger</option>
                                        <option value={"sender"}>Besteller</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId={"user-pin"} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4}>Pin</FormLabel>
                                <Col>
                                    <Form.Control
                                        type={"text"}
                                        inputMode={"numeric"}
                                        value={userToCreate.pin ?? ""}
                                        onChange={(e) => setToCreatePin(e)}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setUserToCreate(null)}>Schließen</Button>
                        <Button variant="primary" onClick={() => saveUser(userToCreate)}>Speichern</Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    );
};

export default UsersView;
