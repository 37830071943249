import React, {useCallback, useEffect, useState} from "react";
import {ButtonGroup} from "react-bootstrap";
import '../style/ItemCard.css'

export interface AvailableItem {
    id: number;
    name: string;
    available: number;
}

interface ItemCardProps {
    item: AvailableItem;
    onCountChanged?: (count: number) => void;
    onAddComment?: (item: AvailableItem) => void;
    hasComment?: boolean;
    count: number;
}

const ItemCard = (props: ItemCardProps) => {
    const increment = useCallback(() => {
        const avail = props.item.available === -1 ? Infinity : props.item.available;
        props.onCountChanged?.(Math.min(avail, props.count + 1));
    }, []);
    const decrement = useCallback(() => {
        props.onCountChanged?.(Math.max(0, props.count - 1));
    }, []);
    return (
        <ButtonGroup className={"item-card"}>
            <table>
                <tbody>
                    <tr>
                        <td className={"add-cell" + (props.count >= props.item.available && props.item.available !== -1 ? " disabled" : "")} colSpan={2}>
                            <button onClick={increment}>{props.item.name}</button>
                        </td>
                        <td className={"count-cell"}>{props.item.available === -1 ? "∞" : props.item.available}</td>
                    </tr>
                    <tr>
                        <td className={"comment-cell" + (props.hasComment ? " has-comment" : "")}>
                            <button onClick={() => props.onAddComment?.(props.item)} disabled={props.item.available === 0}>A</button>
                        </td>
                        <td className={"subtract-cell"}>
                            <button onClick={decrement} disabled={props.item.available === 0}>{props.count}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ButtonGroup>
    )
}

export default ItemCard;
