import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import Orders from "./pages/orders";
import OrderScreen from "./components/OrderScreen";
import LoginPage from "./pages/LoginPage";
import EnterPinPage from "./pages/EnterPinPage";
import AdminPage from "./pages/AdminPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                index: true,
                element: <OrderScreen/>,
            },
            {
                path: "/orders",
                element: <Orders/>
            }
        ],
    },
    {
        path: "/login/:envId",
        element: <LoginPage/>
    },
    {
        path: "/pin/:userId",
        element: <EnterPinPage/>
    },
    {
        path: "/admin",
        element: <AdminPage/>,
    }
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
