import React, {FormEvent, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Form, Image, Spinner} from "react-bootstrap";
import logo from "../logo.png";
import {useLocalStorage} from "usehooks-ts";
import ApiUrl from "../ApiUrl";

const EnterPinPage = () => {
    const [pin, setPin] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState<{ cause: string, text: string }>();
    const {userId} = useParams();
    const navigate = useNavigate();
    const [token, setToken] = useLocalStorage<string>("jwtToken", "");
    const [, setAuthLevel] = useLocalStorage<string>("authLevel", "");

    const submitLogin = (e: FormEvent) => {
        e.preventDefault();
        setSubmitLoading(true);
        fetch(`${ApiUrl}/api/v1/users/${userId}/pin`, {
            method: "POST",
            body: JSON.stringify({
                "pin": pin,
            }),
            headers: {'Authorization': 'Bearer ' + token}
        }).then((resp: Response) => {
            if (!resp.ok) {
                resp.json().then(r => setError(r));
                return;
            }
            setError(undefined);
            console.log("success", resp.body);
            resp.json().then(r => {
                setToken(r.token);
                setAuthLevel("user");
            })
            navigate("/");
        }).catch(console.error)
            .finally(() => setSubmitLoading(false));
    }

    const checkAndSetPin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            setPin(e.target.value);
        } else {
            e.preventDefault();
        }
    }

    return (
        <div className="App">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Link to={`/`} className={"navbar-brand"} title={"Version " + process.env.REACT_APP_VERSION}>
                        <Image src={logo} style={{"height": "1.8rem"}} className={"align-text-top"}/>
                        <Navbar.Brand className={"ms-1 d-none d-lg-inline"}>Leiter M</Navbar.Brand>
                    </Link>
                </Container>
            </Navbar>
            <Container className={"mt-4"} style={{maxWidth: "300px"}}>
                <Form onSubmit={submitLogin}>
                    <Form.Label as={"h4"} htmlFor="inputPin">Pin eingeben</Form.Label>
                    <Form.Control
                        id="inputPin"
                        className={"mt-4"}
                        value={pin}
                        type={"password"}
                        inputMode={"numeric"}
                        onChange={(e) => checkAndSetPin(e)}
                        autoFocus
                    />
                    <Button className={"mt-4 w-100"} variant={"success"} type={"submit"} disabled={submitLoading}>
                        {submitLoading ?
                            <Spinner size={"sm"}/>
                            :
                            <span>Weiter</span>
                        }
                    </Button>
                </Form>
            </Container>
            {error &&
                <Container className={"mt-4"} style={{maxWidth: "500px"}}>
                    <Alert variant={"danger"}>
                        <Alert.Heading>Login fehlgeschlagen</Alert.Heading>
                        <hr/>
                        {error.text}
                    </Alert>
                </Container>
            }
        </div>
    );
}

export default EnterPinPage;
