import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import {Button, Image, Stack, Tab, Tabs} from "react-bootstrap";
import logo from "../logo.png";
import React, {useEffect, useState} from "react";
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faDatabase,
    faSkullCrossbones,
    faUser,
    faWarehouse,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {useLocalStorage} from "usehooks-ts";
import {ReadyState} from "react-use-websocket";
import ApiUrl from "../ApiUrl";
import useWebSocket from "../useWebSocket";
import {AvailableItem} from "../components/ItemCard";
import StockView from "../components/admin/StockView";
import UsersView from "../components/admin/UsersView";

const iconAndText = (icon: IconDefinition, text: string) => {
    return (
        <span><FontAwesomeIcon icon={icon}/><span className={"ms-1"}>{text}</span></span>
    )
}

const AdminPage = () => {
    const [, setPingDelay] = useState(0);
    const [items, setItems] = useState<AvailableItem[]>([]);
    const [token] = useLocalStorage<string>("jwtToken", "");

    const onError = (event: Event) => {
        console.log("onError", event);
    }

    const shouldReconnect = (event: CloseEvent): boolean => {
        console.log("shouldReconnect", event);
        return !event.wasClean;
    }

    const url = `${ApiUrl.replace('http', 'ws')}/ws?token=${token}`
    const {
        sendMessage,
        lastMessage,
        readyState
    } = useWebSocket(url, undefined, onError, shouldReconnect, setPingDelay);

    useEffect(() => {
        console.log("readystate", readyState);
        if (readyState === ReadyState.OPEN) {
            sendMessage(`{"command": "getItemList"}`);
        }
    }, [readyState, sendMessage]);

    useEffect(() => {
        if (lastMessage == null) {
            console.log("set message to null");
            return;
        }
        let msg = JSON.parse(lastMessage.data);
        console.log("new message: " + msg.command + " / " + JSON.stringify(msg.data));
        switch (msg.command) {
            case "itemList":
                setItems(msg.data);
                break;
        }
    }, [lastMessage]);

    const modifyItem = (item: AvailableItem) => {
        sendMessage(JSON.stringify({command: "modifyItem", data: item}));
    }

    return (
        <div className="App">
            <Navbar expand={true} className="bg-body-tertiary">
                <Container>
                    <Link to={`/`} className={"navbar-brand"} title={"Version " + process.env.REACT_APP_VERSION}>
                        <Image src={logo} style={{"height": "1.8rem"}} className={"align-text-top"}/>
                        <Navbar.Brand className={"ms-1 d-none d-lg-inline"}>Leiter M</Navbar.Brand>
                    </Link>
                    <Nav className="me-auto" fill justify>
                        <Nav.Item>
                            <Link className="nav-link" to={`/`}>
                                <Button variant={"outline-secondary"}>
                                    <Stack direction={"horizontal"} style={{height: "1.5rem"}}>
                                        <FontAwesomeIcon icon={faChevronLeft}/>
                                        <span className="text-nowrap ms-2">Zurück</span>
                                    </Stack>
                                </Button>
                            </Link>
                        </Nav.Item>
                    </Nav>
                </Container>
            </Navbar>
            <Container className={"mt-4"}>
                <h2>Administration</h2>
                <Tabs className="mb-3 mt-3">
                    <Tab eventKey="profile" title={iconAndText(faWarehouse, "Lager")}>
                        <StockView items={items} onSaveItem={modifyItem}/>
                    </Tab>
                    <Tab eventKey="home" title={iconAndText(faUser, "Benutzer")}>
                        <UsersView sendMessage={sendMessage} readyState={readyState} lastMessage={lastMessage} />
                    </Tab>
                    <Tab eventKey="contact" title={iconAndText(faDatabase, "Datenbank")}>
                        Tab content for Contact
                    </Tab>
                    <Tab eventKey="danger" tabClassName={"text-danger"} title={iconAndText(faSkullCrossbones, "Danger Zone")}>
                        Danger Zone
                    </Tab>
                </Tabs>
            </Container>
        </div>
    )
}

export default AdminPage;
