import {OrderItem} from "../components/OrderScreen";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {ReadyState, SendMessage} from "react-use-websocket";
import {Link, Outlet, useOutletContext} from "react-router-dom";
import {useLocalStorage} from "usehooks-ts";
import useWebSocket from "../useWebSocket";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Badge, Button, Image, Stack, Toast, ToastContainer} from "react-bootstrap";
import logo from "../logo.png";
import Nav from "react-bootstrap/Nav";
import OnlineStatus from "../components/OnlineStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faGears, faList, faRightFromBracket, faUser} from "@fortawesome/free-solid-svg-icons";
import ApiUrl from "../ApiUrl";
import moment from "moment";
import jwt_decode from "jwt-decode";

type CurrentOrderContextType = {
    currentOrder: OrderItem[];
    setCurrentOrder: React.Dispatch<React.SetStateAction<OrderItem[]>>;
    sendMessage: SendMessage;
    lastMessage: MessageEvent<any> | null;
    readyState: ReadyState;
    setFooterElements: React.Dispatch<React.SetStateAction<ReactNode[]>>;
    setHeaderElements: React.Dispatch<React.SetStateAction<ReactNode[]>>;
}

export function useCurrentOrder() {
    return useOutletContext<CurrentOrderContextType>();
}

interface APINotification {
    from: string;
    content: string | ReactNode;
    time: Date;
    show: boolean;
}

interface TokenData {
    environment: number;
    user: number;
    role: string;
}

const MainPage = () => {
    const [pingDelay, setPingDelay] = useState(0);
    const [currentOrder, setCurrentOrder] = useState<OrderItem[]>([]);
    const [numActiveOrders, setActiveOrders] = useState(0);
    const [footerElements, setFooterElements] = useState<ReactNode[]>([]);
    const [headerElements, setHeaderElements] = useState<ReactNode[]>([]);
    const [notifications, setNotifications] = useState<APINotification[]>([]);
    const [token, setToken] = useLocalStorage<string>("jwtToken", "");
    const [, setAuthLevel] = useLocalStorage<string>("authLevel", "");
    const [, setActiveDestination] = useLocalStorage<number>("activeDestination", -1);
    const [tokenData, setTokenData] = useState<TokenData>();

    const onError = (event: Event) => {
        console.log("onError", event);
    }

    const shouldReconnect = (event: CloseEvent): boolean => {
        console.log("shouldReconnect", event);
        return !event.wasClean;
    }

    const url = `${ApiUrl.replace('http', 'ws')}/ws?token=${token}`
    const {
        sendMessage,
        lastMessage,
        readyState
    } = useWebSocket(url, undefined, onError, shouldReconnect, setPingDelay);

    const logout = () => {
        setToken("");
        setAuthLevel("none");
        setActiveDestination(-1);
    }

    const gotoChooseUser = () => {
        setAuthLevel("environment");
        setActiveDestination(-1);
    }

    const deleteNotification = (idx: number) => {
        setNotifications((prev) => {
            return prev.splice(idx, 1);
        })
    }

    const setShowNotification = useCallback((idx: number, show: boolean) => {
        setNotifications((prev) => {
            let newNotifications = [...prev];
            newNotifications[idx].show = show;
            return newNotifications;
        });
        if (!show) {
            setTimeout(() => {
                deleteNotification(idx);
            }, 2000);
        }
    }, []);

    const createNotification = useCallback((n: APINotification) => {
        setNotifications((prev) => {
            const newNotifications = [...prev, n];
            setTimeout(() => {
                setShowNotification(newNotifications.length - 1, true);
            }, 0);
            return newNotifications;
        });
    }, [setShowNotification]);

    useEffect(() => {
        if (lastMessage == null) {
            return;
        }
        let msg = JSON.parse(lastMessage.data);
        switch (msg.command) {
            case "numOpenOrders":
                setActiveOrders(msg.data);
                break;
            case "orderAck":
                const ackResponse: { id: number; items: OrderItem[] } = msg.data;
                createNotification({
                    from: "System",
                    content: (
                        <div>
                            Bestellung #{ackResponse.id} aufgegeben
                            <hr/>
                            <ul>
                                {ackResponse.items.map((item, idx) => (
                                    <li key={idx}>{item.count}x {item.name}</li>
                                ))}
                            </ul>
                        </div>
                    ),
                    time: new Date(),
                    show: false,
                })
        }
    }, [createNotification, lastMessage]);

    /* when connected to the websocket, immediately request a list of items and destinations */
    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendMessage(`{"command": "getNumOpenOrders"}`);
        }
    }, [readyState, sendMessage]);

    useEffect(() => {
        let decoded = jwt_decode<TokenData>(token);
        console.log("decoded", decoded);
        setTokenData(decoded);
    }, [token]);

    // @ts-ignore
    return (
        <div className="App">
            <Navbar expand={true} sticky={"top"} className="bg-body-tertiary">
                <Container>
                    <Link to={`/`} className={"navbar-brand"} title={"Version " + process.env.REACT_APP_VERSION}>
                        <Image src={logo} style={{"height": "1.8rem"}} className={"align-text-top"}/>
                        <Navbar.Brand className={"ms-1 d-none d-lg-inline"}>Leiter M</Navbar.Brand>
                    </Link>
                    <Nav className="me-auto" fill justify>
                        <Nav.Item>
                            <Link className="nav-link" to={`/`}>
                                <Button variant={"outline-secondary"}>
                                    <Stack direction={"horizontal"} style={{height: "1.5rem"}}>
                                        <FontAwesomeIcon icon={faCartPlus}/>
                                        <span className="d-none d-lg-inline text-nowrap ms-2">Neue Bestellung</span>
                                    </Stack>
                                </Button>
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className="nav-link" to={`/orders`}>
                                <Button variant={"outline-secondary"}>
                                    <Stack direction={"horizontal"} style={{height: "1.5rem"}}>
                                        <FontAwesomeIcon icon={faList}/>
                                        <span className="d-none d-lg-inline text-nowrap ms-2">Bestellungen</span>
                                        {numActiveOrders > 0 &&
                                            <Badge pill bg={"danger"} className={"ms-2"}>{numActiveOrders}</Badge>}
                                    </Stack>
                                </Button>
                            </Link>
                        </Nav.Item>
                    </Nav>
                    {headerElements}
                </Container>
            </Navbar>
            <div className="content">
                <Outlet context={{
                    currentOrder,
                    setCurrentOrder,
                    sendMessage,
                    lastMessage,
                    readyState,
                    setFooterElements,
                    setHeaderElements
                } satisfies CurrentOrderContextType}/>
            </div>
            <Navbar fixed={"bottom"} className="bg-body-secondary">
                <Container>
                    <Nav className="me-auto">
                        <Navbar.Text>
                            <OnlineStatus readyState={readyState} pingDelay={pingDelay}/>
                        </Navbar.Text>
                    </Nav>
                    <Nav className="justify-content-end ms-auto">
                        {footerElements}
                        {tokenData?.role === "admin" &&
                            <Nav.Item>
                                <Link className="nav-link" to={`/admin`}>
                                    <Button variant={"outline-dark"}>
                                        <FontAwesomeIcon icon={faGears}/>
                                    </Button>
                                </Link>
                            </Nav.Item>
                        }
                        <Navbar.Text>
                            <Button className={"ms-2"} variant={"outline-primary"} onClick={gotoChooseUser}>
                                <FontAwesomeIcon icon={faUser}/>
                            </Button>
                            <Button className={"ms-2"} variant={"outline-danger"} onClick={logout}>
                                <FontAwesomeIcon icon={faRightFromBracket}/>
                            </Button>
                        </Navbar.Text>
                    </Nav>
                </Container>
            </Navbar>
            <ToastContainer position={"top-end"} className={"notification"}>
                {notifications.map((n, idx) => (
                    <Toast
                        onClose={() => setShowNotification(idx, false)}
                        show={n.show}
                        animation={true}
                        autohide
                        delay={4000}
                        bg={"light"}
                        key={idx}
                    >
                        <Toast.Header>
                            <strong className="me-auto">{n.from}</strong>
                            <small>{moment(n.time).fromNow()}</small>
                        </Toast.Header>
                        <Toast.Body className={"text-start"}>
                            {n.content}
                        </Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        </div>
    );
};

export default MainPage;
