import ApiUrl from "../ApiUrl";

export default async function login(envId: number, password: string) {
    let resp;
    try {
        resp = await fetch(`${ApiUrl}/api/v1/login`, {
            method: "POST",
            body: JSON.stringify({
                "environment_id": envId,
                "password": password,
            })
        });
    } catch (e) {
        console.log(e);
        throw new Error(`Login fehlgeschlagen`, {cause: e});
    }

    if (!resp.ok) {
        throw await resp.json();
    }
    if (!resp.body) {
        throw "no body";
    }
    return await resp.json();
}
