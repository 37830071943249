import {Button, Col, Form, FormLabel, InputGroup, Modal, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {AvailableItem} from "../ItemCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

interface StockViewProps {
    items: AvailableItem[];
    onSaveItem: (item: AvailableItem) => void;
}

const StockView = ({items, onSaveItem}: StockViewProps) => {
    const [editedRow, setEditedRow] = useState(-1);
    const [editedItem, setEditedItem] = useState<AvailableItem | null>(null);

    useEffect(() => {
        if (editedRow !== -1 && items) {
            console.log("editing row", editedRow);
            setEditedItem(items[editedRow]);
        }
    }, [editedRow, items]);

    const saveItem = (item: AvailableItem | null) => {
        if (item === null) {
            return
        }
        console.log("saving", item);
        setEditedRow(-1);
        onSaveItem(item);
    };

    const setEditedName = (name: string) => {
        setEditedItem((item) => item ? {...item, name} : null);
    };

    const setEditedCount = (count: number) => {
        setEditedItem((item) => item ? {...item, available: count} : null);
    };

    const formatAvailableCount = (item: AvailableItem | null) => {
        if (!item) {
            return "";
        }
        if (item.available === -1) {
            return "∞";
        } else {
            return item.available;
        }
    }

    const countInputType = (item: AvailableItem | null) => {
        if (item?.available !== -1) {
            return "number";
        }
        return "text";
    }

    return (
        <div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Anzahl</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {items && items.map((item, idx) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.available === -1 ? "∞" : item.available}</td>
                            <td>
                                <Button variant={"link"} size={"sm"} className={"p-0"} onClick={() => setEditedRow(idx)}>
                                    <FontAwesomeIcon icon={faPenToSquare}/>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {items && editedRow !== -1 &&
                <Modal
                    show={editedRow !== -1}
                    onHide={() => setEditedRow(-1)}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Bearbeiten</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group as={Row} controlId={"product-name"} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4}>Name</FormLabel>
                                <Col>
                                    <Form.Control type={"text"} value={editedItem?.name ?? ""} onChange={(e) => setEditedName(e.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className={"mb-2"}>
                                <FormLabel column lg={3} xs={4} htmlFor={"control-available"}>Verfügbar</FormLabel>
                                <Col>
                                    <InputGroup>
                                        <Form.Control
                                            id={"control-available"}
                                            type={countInputType(editedItem)}
                                            value={formatAvailableCount(editedItem)}
                                            onChange={e => setEditedCount(Number.parseInt(e.target.value))}
                                            disabled={editedItem?.available === -1}
                                        />
                                        <InputGroup.Text>
                                            <Form.Check
                                                id={"infinite-check"}
                                                checked={editedItem?.available === -1}
                                                onChange={e => setEditedCount(e.target.checked ? -1 : 0)}
                                            />
                                            <label htmlFor={"infinite-check"} className={"form-check-label ms-2"}>unendlich</label>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setEditedRow(-1)}>Schließen</Button>
                        <Button variant="primary" onClick={() => saveItem(editedItem)}>Speichern</Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    );
};

export default StockView;
