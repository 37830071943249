import ApiUrl from "../ApiUrl";

export async function getEnvironments() {
    let resp;
    try {
        resp = await fetch(`${ApiUrl}/api/v1/environments`);
    } catch (e) {
        console.log(e);
        throw new Error(`Abrufen der Veranstaltungen fehlgeschlagen`, {cause: e});
    }
    if (!resp.ok) {
        throw resp.statusText;
    }
    if (!resp.body) {
        throw "no body";
    }
    return await resp.json();
}
