import React, {useEffect, useState} from "react";
import {useCurrentOrder} from "./MainPage";
import {ReadyState} from "react-use-websocket";
import {Button, Stack} from "react-bootstrap";
import "../style/orders.css";
import {OrderItem} from "../components/OrderScreen";
import "moment/locale/de-at";
import OrderCard from "../components/OrderCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";

export class Order {
    constructor(id: number, user: string, destination: string, order_time: Date, items: OrderItem[], acked_by: string, done_by: string) {
        this.id = id;
        this.user = user;
        this.destination = destination;
        this.order_time = order_time;
        this.items = items;
        this.acked_by = acked_by;
        this.done_by = done_by;
    }

    id: number;
    user: string;
    destination: string;
    order_time: Date;
    items: OrderItem[];
    acked_by: string;
    done_by: string;

    public isNew(): boolean {
        return !this.acked_by && !this.done_by;
    }

    public isAcked(): boolean {
        return !!this.acked_by && !this.done_by;
    }

    public isDone(): boolean {
        return !!this.done_by;
    }

    static fromJSON(o: any) {
        return new Order(o.id, o.user, o.destination, new Date(o.order_time), o.items, o.acked_by, o.done_by);
    }
}

const Orders = () => {
    const {sendMessage, lastMessage, readyState, setFooterElements} = useCurrentOrder();
    const [orders, setOrders] = useState<Order[]>([]);
    const [moreAvailable, setMoreAvailable] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const limit = 10;

    const mergeOrders = (prev: Order[], newOrders: Order[]): Order[] => {
        let result: Order[] = [...prev];
        for (let order of newOrders) {
            let idx = prev.findIndex(o => o.id === order.id);
            if (idx !== -1) {
                result[idx] = order;
            } else {
                result.push(order);
            }
        }
        // sort by ID, descending
        result.sort((a, b) => b.id - a.id);
        return result;
    }

    useEffect(() => {
        if (lastMessage == null) {
            return;
        }
        let msg = JSON.parse(lastMessage.data);
        console.log("new message in orders: " + msg.command + " / " + JSON.stringify(msg.data));
        if (msg.command === "ordersList") {
            const newOrders = msg.data.orders.map((o: any) => Order.fromJSON(o));
            setOrders(prev => mergeOrders(prev, newOrders));
            setMoreAvailable(msg.data.more_available);
            console.log(msg.data.orders);
        }
    }, [lastMessage]);

    /* when connected to the websocket, immediately request a list of items */
    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendMessage(`{"command": "getOrdersList", "data": {"offset": ${offset}, "limit": ${limit}}}`);
        }
    }, [readyState, offset, sendMessage]);

    useEffect(() => {
        setFooterElements([]);
    }, [setFooterElements]);

    const setOrderState = (order: Order, state: string) => {
        sendMessage(`{"command": "setOrderState", "data": {"order": ${order.id}, "state": "${state}"}}`);
        sendMessage(`{"command": "getNumOpenOrders"}`);
    };

    const loadMore = () => {
        setOffset(prev => prev + 10);
    };

    return (
        <Stack className={"mt-4"} gap={3}>
            {orders.map((order, idx) => (
                <OrderCard key={order.id} order={order} setOrderState={setOrderState}/>
            ))}
            {moreAvailable &&
                <Button className={"orders-more-button"} variant={"secondary"} onClick={() => loadMore()}>
                    Mehr <FontAwesomeIcon className={"ms-1"} icon={faCaretDown}/>
                </Button>
            }
        </Stack>
    )
}

export default Orders;
