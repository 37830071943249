import React, {useEffect, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import {Image, ListGroup, Spinner} from "react-bootstrap";
import logo from "../logo.png";
import '../App.css';
import {getEnvironments} from "../api/environments";
import ErrorMessage from "../components/ErrorMessage";

const ChooseEnvironmentPage = () => {
    const [environments, setEnvironments] = useState<any[] | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [retry, setRetry] = useState<boolean>(true);

    useEffect(() => {
        if (retry) {
            getEnvironments().then(e => {
                setEnvironments(e);
                setError(null);
            })
                .catch(setError)
                .finally(() => setRetry(false));
        }
    }, [retry]);

    return (
        <div className="App">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Link to={`/`} className={"navbar-brand"} title={"Version " + process.env.REACT_APP_VERSION}>
                        <Image src={logo} style={{"height": "1.8rem"}} className={"align-text-top"}/>
                        <Navbar.Brand className={"ms-1 d-none d-lg-inline"}>Leiter M</Navbar.Brand>
                    </Link>
                </Container>
            </Navbar>
            <Container className={"mt-4"}>
                <h2>Veranstaltung wählen</h2>
                <Container className={"environment-list-container mt-3"}>
                    {error ?
                        <ErrorMessage error={error} retry={() => setRetry(true)}/>
                        :
                        <ListGroup className={"mt-4"} role={"list"}>
                            {!environments &&
                                <ListGroup.Item>
                                    <Spinner animation={"border"} size="sm"></Spinner>
                                </ListGroup.Item>
                            }
                            {environments && environments.map((e: any) => (
                                <ListGroup.Item key={e.id}>
                                    <Link to={`/login/${e.id}`} className={"link-underline link-underline-opacity-0 link-underline-opacity-100-hover stretched-link"}>{e.name}</Link>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    }
                </Container>
            </Container>
        </div>
    );
};

export default ChooseEnvironmentPage;
