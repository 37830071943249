import React, {FormEvent, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Form, Image, Spinner} from "react-bootstrap";
import logo from "../logo.png";
import {useLocalStorage} from 'usehooks-ts';
import login from "../api/login";

const LoginPage = () => {
    const [password, setPassword] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState<{ cause: string, text: string }>();
    const {envId} = useParams();
    const navigate = useNavigate();
    const [, setToken] = useLocalStorage<string>("jwtToken", "");
    const [, setAuthLevel] = useLocalStorage<string>("authLevel", "");

    const submitLogin = (e: FormEvent) => {
        e.preventDefault();
        setSubmitLoading(true);
        const envIdInt = parseInt(envId || "");
        if (isNaN(envIdInt)) {
            setError({cause: "invalidEnvId", text: `Ungültige Veranstaltungs-ID "${envId}"`});
            setSubmitLoading(false);
            return;
        }
        login(envIdInt, password).then((r: any) => {
            setError(undefined);
            setToken(r.token);
            setAuthLevel("environment");
            navigate("/");
        }).catch(setError)
            .finally(() => setSubmitLoading(false));
    }

    return (
        <div className="App">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Link to={`/`} className={"navbar-brand"} title={"Version " + process.env.REACT_APP_VERSION}>
                        <Image src={logo} style={{"height": "1.8rem"}} className={"align-text-top"}/>
                        <Navbar.Brand className={"ms-1 d-none d-lg-inline"}>Leiter M</Navbar.Brand>
                    </Link>
                </Container>
            </Navbar>
            <Container className={"mt-4"} style={{maxWidth: "300px"}}>
                <Form onSubmit={submitLogin}>
                    <Form.Label as={"h4"} htmlFor="inputPassword">Passwort eingeben</Form.Label>
                    <Form.Control
                        type="password"
                        id="inputPassword"
                        aria-describedby="passwordHelpBlock"
                        className={"mt-4"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoFocus
                    />
                    <Button className={"mt-4 w-100"} variant={"success"} type={"submit"} disabled={submitLoading}>
                        {submitLoading ?
                            <Spinner size={"sm"}/>
                            :
                            <span>Einloggen</span>
                        }
                    </Button>
                </Form>
            </Container>
            {error &&
                <Container className={"mt-4"} style={{maxWidth: "500px"}}>
                    <Alert variant={"danger"}>
                        <Alert.Heading>Login fehlgeschlagen</Alert.Heading>
                        <hr/>
                        {error.text}
                    </Alert>
                </Container>
            }
        </div>
    );
};

export default LoginPage;
