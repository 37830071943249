import {Accordion, Badge, Dropdown} from "react-bootstrap";
import moment from "moment/moment";
import React from "react";
import {Order} from "../pages/orders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";

interface OrderCardProps {
    order: Order;
    setOrderState: (order: Order, state: string) => void;
}

const statusToText = (order: Order) => {
    if (order.isDone()) {
        return {text: "Erledigt", className: "bg-success"}
    }
    if (order.isAcked()) {
        return {text: "In Bearbeitung", className: "bg-warning text-muted"}
    }
    return {text: "Neu", className: "bg-danger"}
}

const formatTime = (time: Date) => moment(time).format('Do MMMM YYYY, HH:mm:ss');

const cardClass = (order: Order) => {
    if (order.isDone()) {
        return "status-done";
    }
    if (order.isAcked()) {
        return "status-in-progress";
    }
    return "status-new";
}

const CollapsedOrderCard = ({order}: OrderCardProps) => {
    return (
        <Accordion flush className={"order-item status-done"}>
            <Accordion.Item eventKey={"0"}>
                <Accordion.Button>
                    <span className={"small text-muted"}>#{order.id}</span>
                    <Badge className={"ms-2 " + statusToText(order).className}>{statusToText(order).text}</Badge>
                    <span className={"small text-muted me-3 d-flex flex-fill justify-content-end"} title={formatTime(order.order_time)}>{moment(order.order_time).fromNow()}</span>
                </Accordion.Button>
                <Accordion.Body>
                    <div>
                        <h4 className={"mt-2"}>Von <b className={"text-body-secondary"}>{order.user}</b> für <b className={"text-body-secondary"}>{order.destination}</b>
                        </h4>
                        <ul>
                            {order.items.map((item, idx) => (
                                <li key={idx}>{item.count}x {item.name} <span className={"small"}>{item.comment}</span></li>
                            ))}
                        </ul>
                    </div>
                    {order.isAcked() &&
                        <span className={"small text-muted ms-2"}>Angenommen von <b className={"text-body-secondary"}>{order.acked_by}</b></span>
                    }
                    {order.isDone() &&
                        <span className={"small text-muted ms-2"}>Erledigt von <b className={"text-body-secondary"}>{order.done_by}</b></span>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const ActiveOrderCard = ({order, setOrderState}: OrderCardProps) => {
    return (
        <div className={"order-item " + cardClass(order)}>
            <table>
                <tbody>
                    <tr>
                        <td className={"p-3"}>
                            <div>
                                <span className={"small text-muted"}>#{order.id}</span>
                                <Badge className={"ms-2 " + statusToText(order).className}>{statusToText(order).text}</Badge>
                                <Dropdown className={"d-inline-block float-end"}>
                                    <Dropdown.Toggle variant={"link"} className={"toggle-without-arrow small ms-1 py-0 text-body align-text-bottom"}>
                                        <FontAwesomeIcon icon={faEllipsisVertical}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className={"text-danger"}>Stornieren</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <span className={"small text-muted ms-auto float-end"} title={formatTime(order.order_time)}>{moment(order.order_time).fromNow()}</span>
                            </div>
                            <div>
                                <h4 className={"mt-2"}>
                                    Von <b className={"text-body-secondary"}>{order.user}</b> für <b className={"text-body-secondary"}>{order.destination}</b>
                                </h4>
                                <ul>
                                    {order.items.map((item, idx) => (
                                        <li key={idx}>{item.count}x {item.name} {item.comment !== "" ? <span className={"fst-italic"}>({item.comment})</span> : <></>}</li>
                                    ))}
                                </ul>
                            </div>
                            {order.isAcked() &&
                                <span className={"small text-muted ms-2"}>Angenommen von <b className={"text-body-secondary"}>{order.acked_by}</b></span>
                            }
                            {order.isDone() &&
                                <span className={"small text-muted ms-2"}>Erledigt von <b className={"text-body-secondary"}>{order.done_by}</b></span>
                            }
                        </td>
                    </tr>
                    {!order.done_by && !order.acked_by &&
                        <tr className={"ack-button-row"}>
                            <td className={"ack-button-col"}>
                                <button className={"ack-button"} onClick={() => setOrderState(order, "inProgress")}>Annehmen</button>
                            </td>
                        </tr>
                    }
                    {!order.done_by && order.acked_by &&
                        <tr className={"ack-button-row"}>
                            <td className={"ack-button-col"}>
                                <button className={"done-button"} onClick={() => setOrderState(order, "done")}>Erledigt</button>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

const OrderCard = ({order, setOrderState}: OrderCardProps) => {
    if (order.isDone()) {
        return <CollapsedOrderCard order={order} setOrderState={setOrderState}/>
    }
    return <ActiveOrderCard order={order} setOrderState={setOrderState}/>
}

export default OrderCard;
