import React from "react";
import {Alert, Button} from "react-bootstrap";

function formatError(error: Error): React.JSX.Element {
    return (
        <p>
            <>
                {error.toString()}
                <br/>
                {error.cause &&
                    <small>
                        {error.cause.toString()}
                    </small>
                }
            </>
        </p>
    );
}

const ErrorMessage = ({error, retry}: { error: Error, retry: () => void }) => {
    return (
        <Alert variant={"danger"}>
            <Alert.Heading as={"h5"}>Fehler</Alert.Heading>
            {formatError(error)}
            <Button variant={"secondary"} onClick={retry}>Wiederholen</Button>
        </Alert>
    );
};

export default ErrorMessage;
